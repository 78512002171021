import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const ExecutiveCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Executive Coaching in London";
	const description =
		"Executive coaching can give you objective feedback, assist you in setting and achieving goals, and support you in making difficult choices.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Executive Coaching",
				item: {
					url: `${siteUrl}/executive-coaching`,
					id: `${siteUrl}/executive-coaching`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/executive-coaching`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Executive Coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Executive coaching London"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								You can’t do it alone. You need a trusted partner who can
								provide objective feedback, help you set and accomplish goals,
								and help you make tough decisions. Your partner should be
								someone who knows you, has a good grasp of the industry you're
								in and understands the challenges you face.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Are executive coaches the answer?
							</h3>
							<p>
								With a business career spanning over 35 years, I’ve come across
								hundreds of executives in London who struggle with daily work
								and leadership decisions, while wanting more from their lives or
								careers. Who dislike the regular 9-5, have difficult decisions
								to make or just desperately need a change to help them live the
								rewarding, prosperous and fulfilling life they want for
								themselves and their families.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">An executive crystal ball</h3>

							<p>
								So let me ask you, If you had a crystal ball and could look into
								the future right now, what would life feel like if you…
							</p>
							<ul className="pl-4">
								<li>Were brimming with self-confidence? </li>
								<li>Had improved leadership and communication skills?</li>
								<li>Were more assertive and decisive?</li>
								<li>
									Had an injection of motivation and more fire in your belly?{" "}
								</li>
								<li>Changed your career or accepted that new promotion? </li>
								<li>
									Or took a ‘leap of faith’ and invested in that exciting idea?
								</li>
							</ul>
							<p>
								I expect life would be drastically different if you could
								suddenly realise these ‘what if’s?’.
							</p>
							<p>
								So let me ask you, what’s holding you back from turning these
								‘what if’s?’, into ‘when’s?’
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">What’s holding you back?</h2>
							<ul className="pl-4">
								<li>
									Are you lonely at the top, overloaded with responsibility and
									decisions?
								</li>
								<li>
									At a crossroads, weighing up important life or work decisions?
								</li>
								<li>
									Do you want to understand yourself better and what makes you
									tick, your strengths and weaknesses and how to maximize them?
								</li>
								<li>Finding you’re not as productive as you used to be?</li>
								<li>
									Are you looking for your passion and true purpose in life?
								</li>
								<li>
									Or perhaps you’re just stuck in a rut and need to find a way
									forward?
								</li>
								<li>
									Do you have some new ideas about how to transform your
									business and want to brainstorm them with an experienced,
									impartial executive coach?
								</li>
							</ul>
							<p>
								When we take the time to pause and reflect on our careers, we
								can all say we’ve experienced these feelings over the years -
								they’re the things that hold us all back.
							</p>
							<p>
								However, the way we overcome these challenges will shape an
								executive career for years to come. So when you’re at a
								crossroads, it’s down to you to step in the right direction.
							</p>
							<p>
								This is where my executive and leadership coaching comes into
								play.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								How executive coaching and mentoring works
							</h2>
							<p>
								As an international executive coach based in London, my job is
								to help and support leaders like you to find your way forward,
								set goals and hold you accountable. Together we’ll get you to
								where you want to be and tackle the ever-changing work
								environment head-on.
							</p>
							<p>
								With virtual executive coaching sessions at a mutually
								convenient time that cover:
							</p>
							<ul className="pl-4">
								<li>Professional and personal development</li>
								<li>Leadership coaching</li>
								<li>Business coaching</li>
								<li>Career coaching</li>
							</ul>
							<p>
								Unlike some executive coaches in London with a pre-prepared
								coaching process - these sessions are tailor-made for you and
								designed to give you or your organization a way forward, with
								the direction you need to thrive and build a positive future for
								yourself and those around you.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Why should you trust this executive coach in London?
							</h2>
							<p>
								I’ve been self-employed my entire working life, so as you can
								imagine, I have been through it all: the highs and the lows.
							</p>
							<p>
								So, by combining my business qualifications (DMS & MBA), five
								years of counselling and psychotherapy training (Person Centred
								and Transactional Analysis) and almost four decades of success
								in business, I can work with you to align your career and
								personal goals to help you get where you want to be in your
								life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Some nice words from my executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Daniel Brookbank. Chief Executive Officer."
						review="At the age of 50 and having reached a high level in my chosen field I knew that I needed to look at what I was doing and how it fitted with my work/life balance. I wanted the opportunity to work in a more flexible manner and to have time to explore and succeed in other projects. Jason helped me to analyse where I am now and where I want to be, but much more importantly, he helped me find the path to my future. From starting with a whole lot of unanswered questions I ended up with clear answers and achievable goals. Jason’s questioning was clear and challenging and his advice sound and logical. He spent time making me think and understand and he helped me to see clearly through a whole lot of different ideas. I was very pleased that by the time we came to the end of our sessions he had pointed and guided me in such a way that I know what I need to do and how to do it. I would recommend Jason to anyone who is looking to move their career forward or just needs some guidance to help them make changes."
						linkedIn="https://www.linkedin.com/in/daniel-brookbank-83b88b2a/"
						profilePic={daniel}
					/>

					<HorizontalTestimonial
						name="Dr Selda Koydemir."
						review="Jason has helped me get clear on what I really want to do, where I want to go, what strengths I have and how I can use them to achieve my goals, and encouraged me to be more proactive around my business ideas. He has a friendly and challenging attitude which has put me at ease, but also question my mindset and behaviours at the right time. I’m very glad that I’ve had the chance to work with such a great mentor."
						linkedIn="https://www.linkedin.com/in/seldakoydemir/"
						profilePic={andrew}
					/>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Book a call today</h3>
							<p>
								Let’s chat, get to know each other and see if there’s a way for
								us to work together with some executive coaching.
							</p>
							<p>
								P.S. Remember, no matter how good you are in your role, you know
								full well that you can’t do it alone. You need a trusted
								executive coaching partner who can provide objective feedback,
								help you set and accomplish goals, and help you make tough
								decisions.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default ExecutiveCoachingPage;
